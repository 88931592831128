<template>
  <base-section id="startup-list" space="0">
    <v-container class="px-8">
      <v-fade-transition
        v-if="$store.state.cart.products && $store.state.cart.products[0]"
        group
        hide-on-leave
      >
      </v-fade-transition>

      <base-section v-else>
        <v-row v-if="loading" justify="center">
          <v-progress-circular
            :width="15"
            :size="100"
            color="rgba(184, 184, 184,0.4)"
            indeterminate
          />
        </v-row>
        <v-row v-else justify="center">
          <v-col cols="12" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="12">
            <base-subheading
              class="text-center text--disabled"
              title="Carrito vacio!"
            />
            <div class="text-center">
              <base-btn :to="`/${$route.params.tableId}/menu`">
                Agregar productos
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </base-section>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "ShoppingCartSection",

  data: () => ({
    details: "",
    loading: false,
  }),

  methods: {
    formatPrice(x, float = 0) {
      x = Number(x);
      let price = Number.parseFloat(x).toFixed(float).replace(".", ",");

      return `${price}`;
    },
    async sendOrder() {
      this.loading = true;
      //
    },
  },

  computed: {
    apiUrl() {
      return process.env.VUE_APP_API;
    },
  },
};
</script>

<style lang="sass">
.v-image
  transition: all .3s ease-in-out
.startup-list-card
  &:hover
    h6
      transition: color .3s
      color: var(--v-primary-base)
    .v-image
      transform: scale(1.035)
</style>
